@import "../Assets/Styles/_vars.scss";

.rewardManagementbtn {
    button {
        border-radius: 5px;
        font-size: 14px;
        font-weight: 400;
        transition: all 0.25s;
        border: none;
        box-shadow: none;
        height: 45px;
        cursor: pointer;
        white-space: nowrap;
        padding: 4px 15px;

    }

    .settle {
        color: #fff !important;
        background-color: $traditionalTypes__green;
        min-width: 135px;
    }

}

.rewardManagment {
    table {

        th,
        td {
            &:nth-child(2) {
                padding: 0 !important;
            }

            &:first-child {
                padding-right: 10px !important;
            }
        }
    }
}

// .ant-checkbox {
//     display: none;
// }

.ml-50 {
    margin-left: 50px;
}

.ml-100 {
    margin-left: 100px;
}