@import "../../Assets/Styles/_vars.scss";
@import "../../Assets/Styles/_mixins.scss";

.SelectOuter {
    position: relative;
    .innerTxtField {
        position: absolute;
        top: 13px;
        left: 16px;
        @include theme() {
            color: theme-get("selectTxtColor") !important;
          }
    }
    .label {
      letter-spacing: 0px;
      @include theme() {
        color: theme-get("titlehead");
      }
      opacity: 1;
      width: 100%;
      text-align: left;
      @include fluid-type(320px, 1920px, 12px, 14px);
      display: flex;
      padding-bottom: 10px;
    }
    .ant-select.customSelect.paddingLeft {
      .ant-select-selector {
        padding-left: 80px !important;
      }
    }
}
.customSelect.ant-select {
  border-radius: 5px;
  @include theme() {
    color: theme-get("selectTxtColor") !important;
  }
  @include fluid-type(320px , 1920px , 12px , 14px);
  width: 100%;
  background-color: transparent;
  img {
    margin-right: 20px;
  }
  .ant-select-arrow {
    display: none;
  }
  position: relative;
  &::after {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    width: 9px;
    height: 6px;
    content: "";
    background-image: url('../../Assets/Images/DownArrowIcon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

  }
  &-open {
    &::after {
        transform: rotate(180deg);
    }
  }
  .ant-select-item,
  .ant-select-selection-item {
    background-color: transparent !important;
    @include theme() {
      color: theme-get("selectTxtColor") !important;
    }
  }
  .ant-select-selector {
    background: transparent !important;
    border: 1px solid;
    border-radius: 4px !important;
    z-index: 1;
    // height: 45px !important;
  @include theme() {
    border-color: theme-get("selectBorder") !important;
    color: theme-get("selectTxtColor") !important;
  }
    &:hover,&:focus, &:focus-visible, &:focus-within {
        border-color: $CommonButton !important;
    outline: none !important;
    box-shadow: none !important;
    
      }
  }
  &:hover,&:focus, &:focus-visible, &:focus-within {
    border-color: $CommonButton !important;
    outline: none !important;
    box-shadow: none !important;
  }
  &.innerPagesSelect {
    text-align: right;
  }
  &.fillBg {
    background-color: $selectFillBg;
  }
}
.ant-select-dropdown {
    img {
        margin-right: 20px;
      }
  @include theme() {
    background-color: theme-get("selectOptionsBG") !important;
  }
  box-shadow: rgb(0 0 0 / 8%) 0px 7px 14px, rgb(0 0 0 / 8%) 0px 3px 6px,
    rgb(94 102 115 / 90%) 0px 0px 1px inset !important;
  .ant-select-item-option {
    @include theme() {
      color: theme-get("selectTxtColor") !important;
    }
    &-selected {
      @include theme() {
        color: theme-get("selectTxtColor") !important;
        background-color: theme-get("selectHoverableBg") !important;
      }
    }
    &:hover {
      @include theme() {
        background-color: theme-get("selectHoverableBg") !important;
        color: theme-get("selectTxtColor") !important;
      }
    }
    &:focus {
      background-color: unset !important;
    }
  }
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding: 6.5px 16px !important;
    height: auto !important;
}

#rc_select_1 {
    height: 100%;
}
.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: transparent !important;
}